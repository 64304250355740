<template>
  <div class="black-friday-promotion"
       :class="[{'black-friday-promotion__menu-expanded': $store.state.menu.isExpanded}]"
  >
    <div class="black-friday-promotion__bf-cm-get-up-to-50">
      <div class="black-friday-promotion__bf-cm" />
      <div class="black-friday-promotion__close-btn__mobile">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="close" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M12 3a9 9 0 100 18 9 9 0 000-18zm2.439 10.161a.9.9 0 010 1.278.9.9 0 01-1.278 0L12 13.269l-1.161 1.17a.9.9 0 01-1.278 0 .9.9 0 010-1.278L10.731 12l-1.17-1.161a.904.904 0 011.278-1.278L12 10.731l1.161-1.17a.904.904 0 111.278 1.278L13.269 12l1.17 1.161z" fill="#005AEE"/></svg>
      </div>

      <!-- Desktop-->
      <div class="black-friday-promotion__get-up-to-50__counter-desktop" />
    </div>

    <img class="black-friday-promotion__get-up-to-50__desktop" src="../../../../assets/img/black-friday-promotion/sale-desktop.png">
    <!-- /Desktop-->

    <!-- Tablet -->
    <div class="black-friday-promotion__get-up-to-50__50__tablet">
      <div class="black-friday-promotion__get-up-to-50__counter-tablet">
        <black-friday-promotion-countdown />
      </div>
    </div>
    <!-- /Tablet -->

    <!-- Mobile -->
    <div class="black-friday-promotion__countdown-mobile">
      <div class="black-friday-promotion__countdown-mobile__counter">
        <black-friday-promotion-countdown />
      </div>
      <div class="black-friday-promotion__countdown-mobile__button" @click.prevent="openBilling" />
    </div>
    <!-- /Mobile -->

    <!-- Grab Now Button -->
    <div class="black-friday-promotion__count-button">
      <div class="black-friday-promotion__count-button__button">
        <a @click.prevent="openBilling">
          <img src="../../../../assets/img/black-friday-promotion/btn-web.png" alt="">
        </a>
      </div>
    </div>
    <!-- /Grab Now Button -->

    <!-- Close banner -->
    <div class="black-friday-promotion__close-btn">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="close" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M12 3a9 9 0 100 18 9 9 0 000-18zm2.439 10.161a.9.9 0 010 1.278.9.9 0 01-1.278 0L12 13.269l-1.161 1.17a.9.9 0 01-1.278 0 .9.9 0 010-1.278L10.731 12l-1.17-1.161a.904.904 0 011.278-1.278L12 10.731l1.161-1.17a.904.904 0 111.278 1.278L13.269 12l1.17 1.161z" fill="#005AEE"/></svg>
    </div>
    <!-- /Close banner -->
  </div>
</template>

<script>
import BlackFridayPromotionCountdown
  from '@/views/Dashboard/BlackFridayPromotion/Countdown/BlackFridayPromotionCountdown.vue'

export default {
  name: 'BlackFridayPromotionBanner',
  components: {
    BlackFridayPromotionCountdown,
  },

  methods: {
    close () {
      this.$http.post('/api/v1/promotions/settings',
        {'settingName': 'showChristmasPromo2023Banner'}
      ).then(() => {
        this.$store.commit('settings/setSetting',
          { category: 'christmasPromo2023', name: 'showChristmasPromo2023Banner', value: false })
      });
    },

    openBilling () {
      this.$store.commit('billing/setBillingDialogStep', 'plans')
      this.$store.commit('billing/setVisibleDialogBilling', true)
    }
  }
}
</script>

<style lang="scss">
.black-friday-promotion {
  height: unset;
  background: url("@/assets/img/black-friday-promotion/background-mobile.png") no-repeat right;
  background-size: cover;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    background: url("@/assets/img/black-friday-promotion/background-tablet.png") no-repeat;
    background-size: cover;
    width: 100%;
    height: 145px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    gap: 24px;
  }

  @media screen and (min-width: 1025px) {
    background: url("@/assets/img/black-friday-promotion/background-web.png") no-repeat left;
    height: 117px !important;
    background-size: cover;
    background-position: center !important;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: calc(100% - 72px);
    position: fixed;
    z-index: 7;
    top: 0;
    right: 0;
    gap: 16px;

    &__menu-expanded {
      width: calc(100% - 84px);
    }

    & > *, img {
      max-height: 100%;
    }
  }

  &__cyber-monday-tablet {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 48px;
    height: 100%;

    @include phone-down {
      display: none;
    }

    @media screen and (min-width: 1200px) {
      display: none;
    }

    &__button {
      cursor: pointer;
    }
  }

  &__countdown-mobile {
    width: 100%;
    height: 47px;
    background-color: #05011A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px;
    gap: 8px;

    &__counter {
      .black-friday-2023__countdown {
        @media screen and (max-width: 360px) {
          width: 161px;
        }
      }
    }

    &__button {
      cursor: pointer;
      display: flex;
      align-items: center;

      background: url("@/assets/img/black-friday-promotion/btn-mobile.png");
      width: 90px;
      height: 24px;
    }

    @include phone-up {
      display: none;
    }
  }

  &__bf-cm-get-up-to-50 {
    display: flex;
    justify-content: center;
    width: 100%;
    background: url("@/assets/img/black-friday-promotion/triangle-mobile.png") no-repeat;
    background-size: cover;

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      display: flex;
      background: url("@/assets/img/black-friday-promotion/triangle-tablet.png") no-repeat;
      width: 402px;
      justify-content: flex-start;
      padding-left: 24px;
    }

    @media screen and (min-width: 1025px) {
      background: url("@/assets/img/black-friday-promotion/triangle-tablet.png") no-repeat;
      width: 360px;
      justify-content: flex-start;
      padding-left: 24px;
    }

    @media screen  and (min-width: 1200px) {
      height: 100%;
      align-content: center;
      width: 370px;
      justify-content: flex-start;
      background: url("@/assets/img/black-friday-promotion/blur-left.png") no-repeat 50%;
    }

    .bf-cm-mobile-image {
      display: none;

      @include phone-down {
        display: block;
      }
    }

    @include tablet-up {
      display: flex;
    }
  }

  &__get-up-to-50 {
    height: 100%;

    &__desktop {
      display: none;

      @media screen and (min-width: 1200px) {
        display: block;
      }
    }

    &__counter-desktop {
      display: none;

      @media screen and (min-width: 1200px) {
        display: flex;
        align-self: flex-end;
        justify-content: space-between;
      }
    }

    &__counter-tablet {
      display: none;

      @media screen and (min-width: 769px) {
        display: block;
      }
    }

    &__50 {
      &__tablet {
        display: none;

        @media screen and (min-width: 769px) and (max-width: 1024px) {
          background: url("@/assets/img/black-friday-promotion/timer_blur.png") no-repeat 50%;
          width: 250px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }

        @media screen and (min-width: 1025px) {
          background: url("@/assets/img/black-friday-promotion/timer_blur.png") no-repeat 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 280px;
          height: 100%;
          margin-right: 60px;
        }

        @media screen and (min-width: 1200px) {
          margin-right: 0;
        }
      }

      &__desktop {
        margin-left: -100px;
        align-self: baseline;

        @media screen and (min-width: 1200px) {
          margin-left: 20px;
          margin-bottom: 5px;
          height: 117px;

          .countdown-component {
            gap: 14px;
          }
        }

        @media screen and (min-width: 1600px) {
          align-self: baseline;
          margin-left: 0;
        }

        @media screen and (max-width: 1199px) {
          display: none;
        }

        &__with-counter {
          @media screen and (min-width: 1600px) {
            margin-left: -60px !important;
          }

          @media screen and (min-width: 1200px) {
            margin-left: -120px !important;
          }
        }
      }

      @include phone-down {
        width: 150px;
        display: none;
      }
    }
  }

  &__bf-cm {
    background: url("@/assets/img/black-friday-promotion/bf-promotion-mobile.png") no-repeat;
    width: 228px;
    height: 90px;
    margin-top: 8px;

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      height: 145px;
      width: 228px;
      background: url("@/assets/img/black-friday-promotion/bf-promotion.png") no-repeat center;
      background-size: cover;
    }

    @media screen and (min-width: 1025px) and (max-width: 1199px) {
      background: url("@/assets/img/black-friday-promotion/bf-promotion.png") no-repeat center;
      height: 116px;
      width: 230px;
      margin-top: 0;
    }

    @media screen and (min-width: 1200px) {
      background: url("@/assets/img/black-friday-promotion/bf-promotion-large.png") no-repeat 50%;
      width: 270px;
      height: 100%;
      margin-top: 0;
    }
  }

  &__white-label {
    display: none;

    @media screen and (min-width: 1600px) {
      margin-left: 50px !important;
    }

    @media screen and (min-width: 1400px) {
      display: flex;
      margin-left: 50px;
      align-self: center;
    }

    &__with-counter {
      @media screen and (min-width: 1400px) {
        margin-left: 10px !important;
      }

      @media screen and (min-width: 1600px) {
        margin-left: 50px !important;
      }
    }
  }

  &__close-btn {
    display: none;

    svg {
      cursor: pointer;

      path {
        fill: $white;
      }
    }

    @include phone-up {
      display: block;
      position: absolute;
      top: 12px;
      right: 12px;
    }

    &__mobile {
      display: block;
      position: absolute;
      top: 4px;
      right: 4px;
      padding: 4px;

      svg {
        cursor: pointer;

        path {
          fill: $white;
        }
      }

      @include phone-up {
        display: none;
      }
    }
  }

  &__count-button {
    justify-content: center;
    display: none;

    @include phone-up {
      display: block;
      margin-right: 50px;
    }

    &__button {
      cursor: pointer;
      align-self: center;

    }
  }
}
</style>
