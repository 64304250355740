<template>
  <div
    :class="[
      'main-nav-bar-mobile__item',
      { 'active': isActive() }
    ]"
  >
    <router-link
      :to="{ name: routeName }"
      @click.native="onClick"
    >
      <div v-html="icon" />
      <span>{{ label }}</span>
    </router-link>
  </div>
  <!-- /Main Navigation Item -->

</template>

<script>
export default {
  name: 'MainNavigationItemMobile',

  props: {
    label: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      required: true
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  },

  methods: {
    isActive () {
      if (this.routeName === this.$route.name) {
        return true
      }

      return this.$route.path.split('/')[1] === this.routeName
    },
  }
}
</script>

<style lang="scss">
.main-nav-bar-mobile__item {
  display: flex;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  flex-grow: 0;
  flex-basis: 100%;
  padding-left: 2px;
  padding-right: 2px;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    flex-direction: column;
    padding-top: 10px;

    span {
      margin-top: 2px;
      color: $shade-900;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
    }

    svg {
      margin: 0 auto;

      path.customize-fill {
        fill: $shade-800;
      }
    }
  }

  &.active {
    path.customize-fill {
      fill: var(--primary-900);
    }

    span {
      margin-top: 2px;
      color: var(--primary-900);
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 3px;
      background-color: var(--primary-900);
    }
  }
}
</style>
