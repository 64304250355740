<template>
  <div v-if="visible" class="black-friday-promotion__countdown">
    <div class="black-friday-promotion__countdown__holder">
      <span class="black-friday-promotion__countdown__holder__days">
        {{ days }}
        <span class="black-friday-promotion__countdown__holder__mobile-text">d</span>
      </span>
      <span class="black-friday-promotion__countdown__holder__text">Days</span>
    </div>
    <div class="black-friday-promotion__countdown__holder">
      <span class="black-friday-promotion__countdown__holder__hours">
        {{ hours }}
        <span class="black-friday-promotion__countdown__holder__mobile-text">hr</span>
      </span>
      <span class="black-friday-promotion__countdown__holder__text">Hours</span>
    </div>
    <div class="black-friday-promotion__countdown__holder">
      <span class="black-friday-promotion__countdown__holder__minutes">
        {{ minutes }}
        <span class="black-friday-promotion__countdown__holder__mobile-text">min</span>
      </span>
      <span class="black-friday-promotion__countdown__holder__text">Min</span>
    </div>
    <!--    <div class="black-friday-promotion__countdown__holder">-->
    <!--      <span class="black-friday-promotion__countdown__holder__seconds">-->
    <!--        {{ seconds }}-->
    <!--        <span class="black-friday-promotion__countdown__holder__mobile-text">sec</span>-->
    <!--      </span>-->
    <!--      <span class="black-friday-promotion__countdown__holder__text">Sec</span>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'BlackFridayPromotionCountdown',

  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },

  created () {
    this.initializeClock()
  },

  methods: {
    initializeClock() {
      let endTime = new Date("Nov 29, 2024 23:59:59").getTime();
      const resetTime = new Date("Dec 17, 2024 00:00:00").getTime();

      const updateClock = () => {
        const now = new Date().getTime();
        let total = endTime - now;

        if (total < 1) {
          endTime = resetTime;
          total = endTime - now;
          clearInterval(updateClock);
          setTimeout(() => {
            this.initializeClock();
          });
        }

        this.days = Math.floor(total / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((total % (1000 * 60)) / 1000);
      };

      updateClock();
      setInterval(updateClock, 1000);
    }
  }
}
</script>

<style lang="scss">
.black-friday-promotion {
  &__countdown {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 24px;

    @media screen and (min-width: 769px) {
      height: 80px;
      width: 154px;
      background: #05011A;
      border-radius: 8px;
      padding: 16px;
    }

    @include phone-down {
      justify-content: flex-start;
    }

    &__holder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 4px;

      &__days, &__hours, &__minutes, &__seconds {
        height: 29px;
        text-align: center;
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        gap: 8px;

        @media screen and (min-width: 769px) and (max-width: 1199px) {
          padding: 3px 0 6px 0;
          font-size: 24px;
          line-height: 24px;
        }

        @media screen and (max-width: 768px) {
          padding: 4px 5px 1px 5px;
          font-size: 24px;
          line-height: 24px;
          height: 24px;

          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1px;
        }
      }

      &__text {
        text-align: center;
        font-weight: 400;
        color: #FFF;
        font-size: 10px;
        line-height: 10px;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &__mobile-text {
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        align-self: flex-end;

        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
  }
}
</style>